import { Environment } from "@react-three/drei";
import CameraControls from "../../components/CameraControls";
import Model from "./components/Model";
import Hotspots from "../../components/Hotspots";
import { Suspense } from "react";
import Loading from "../../components/Loading";
import PinpointControl from "../../components/DevTools/PinpointControl";

interface SceneProps {
  model: Model;
}

const Scene = ({ model }: SceneProps) => {
  return (
    <>
      <CameraControls
        position={model.camera}
        min_distance={model.minZoom ?? 3.0}
        max_distance={model.maxZoom ?? 6.0}
      />
      <Suspense fallback={<Loading />}>
        <Hotspots hotspots={model.hotspots} />
     
        <Model model={model} />
        <Environment preset="city" />
      </Suspense>
    </>
  );
};

export default Scene;
