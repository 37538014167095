import Toggle from "../../../../../components/Toggle";

interface OptionProps {
  layer: PeriodLayer;
  onChange: (value: boolean) => void;
}

const Option = ({ layer, onChange = () => null }: OptionProps) => {
  return (
    <div className="m-3 cursor-pointer md:m-2">
      <Toggle onChange={onChange}>
        <div
          style={{ backgroundColor: layer.color }}
          className="mr-2 inline-block h-[22px] w-[22px] md:h-[15px] md:w-[15px]"
        ></div>
        <span className="md:text-[13px]">{layer.name}</span>
      </Toggle>
    </div>
  );
};

export default Option;
