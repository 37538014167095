import useStore from "../../store";
import shallow from "zustand/shallow";
import Option from "./Option";
import { useEffect } from "react";

const PeriodSelector = () => {
  const periods = useStore((state) => state.map?.periods);
  const [selected_period, setSelectedPeriod] = useStore(
    (state) => [state.selected_period, state.setSelectedPeriod],
    shallow,
  );

  useEffect(() => {
    if (selected_period) {
      let r = document.querySelector(":root");
      if (r instanceof HTMLElement) {
        r.style.setProperty("--period-color", selected_period.color);
      }
    }
  }, [selected_period]);

  return (
    <div className="main_period_cont flex w-screen flex-row items-end p-8">
      {selected_period &&
        periods.map((period) => (
          <Option
            key={`${period.slug}-option`}
            selected={period.slug === selected_period.slug}
            onSelected={setSelectedPeriod}
            period={period}
          />
        ))}
    </div>
  );
};

export default PeriodSelector;
