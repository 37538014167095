import { OrbitControls } from "@react-three/drei";
import { useThree } from "@react-three/fiber";
import { useEffect } from "react";
import * as THREE from "three";

interface ControlsProps {
  position: Vector3Tuple;
}

const Controls = ({ position }: ControlsProps) => {
  const { events } = useThree();
  const { camera }: { camera: THREE.PerspectiveCamera } = useThree();

  const zoom = ({ deltaY }: WheelEvent) => {
    const fov = camera.fov + deltaY * 0.05;
    camera.fov = THREE.MathUtils.clamp(fov, 20, 75);
    camera.updateProjectionMatrix();
  };

  useEffect(() => {
    camera.fov = 40;
    camera.position.set(...position);
    camera.updateProjectionMatrix();
  }, []);

  useEffect(() => {
    if (events.handlers) {
      events.handlers.onWheel = zoom as EventListener;
    }
  }, [events.handlers]);

  return (
    <OrbitControls
      enablePan={false}
      enableZoom={false}
      dampingFactor={0.1}
      rotateSpeed={-0.25}
      makeDefault
      onEnd={() => console.log("Camera position:", camera.position.toArray())}
    />
  );
};

export default Controls;
