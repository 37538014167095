import { MeshStandardMaterial } from "three";

type MeshElementProps = JSX.IntrinsicElements["mesh"];

interface MeshProps extends MeshElementProps {
  opacity?: number;
  show: boolean;
}

const Mesh = ({
  opacity,
  material: oMaterial,
  show = true,
  ...props
}: MeshProps) => {
  if (!show) return null;

  const material = new MeshStandardMaterial();
  material.copy(oMaterial as MeshStandardMaterial);

  if (opacity && opacity !== 1) {
    // TODO: Fix flickering or set side to null
    // material.side = null!;
    material.format = null!;
    material.transparent = true;
    material.opacity = opacity;
  }
  return <mesh material={material} {...props} />;
};

export default Mesh;
