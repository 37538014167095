import THREE from "three";
import { useGLTF } from "@react-three/drei";
import { useEffect, useState } from "react";
import { GLTF } from "three-stdlib";
import Group from "./Group";
import Hotspots from "../../../../components/Hotspots";

type ModelStructure = GLTF & {
  nodes: {
    Scene: THREE.Group;
  };
};

interface PeriodProps {
  period: MapPeriod;
  show?: boolean;
}

const Period = ({ period, show = true }: PeriodProps) => {
  const [opacity, setOpacity] = useState(1);
  const {
    nodes: { Scene: rootScene },
  } = useGLTF(period.src) as ModelStructure;

  useEffect(() => {
    setOpacity(show ? 1 : 0.8);
  }, [show]);

  return (
    <>
      {show && <Hotspots hotspots={period.hotspots} />}
      <group dispose={null}>
        {rootScene.children.map((child) => (
          <Group
            key={child.name}
            node={child}
            opacity={opacity}
            show={show}
          ></Group>
        ))}
      </group>
    </>
  );
};

export default Period;
