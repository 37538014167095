import { Canvas, useLoader } from "@react-three/fiber";
import { Suspense } from "react";
import { BackSide, TextureLoader } from "three";
import Hotspots from "../../components/Hotspots";
import Loading from "../../components/Loading";
import Controls from "./Controls";

interface PanoramaProps {
  panorama: Panorama;
}

const Panorama = ({ panorama: { src, hotspots, camera } }: PanoramaProps) => {
  const texture = useLoader(TextureLoader, src);

  return (
    <Canvas>
      <Controls position={camera} />
      {/* TODO: Replace console log with Devtool component */}
      <Suspense fallback={<Loading />}>
        <mesh
          scale={[-1, 1, 1]}
          onPointerDown={({ point }) =>
            console.log("Click position:", point.toArray())
          }
        >
          <sphereBufferGeometry args={[10, 60, 40]} />
          <meshBasicMaterial map={texture} side={BackSide} />
        </mesh>
      </Suspense>
      <Hotspots hotspots={hotspots} />
    </Canvas>
  );
};

export default Panorama;
