import OverlayGrid from "../../../../components/Overlay/OverlayGrid";
import Player from "../../../../components/Overlay/Player";
import TimeMachineLink from "../../../../components/Overlay/TimeMachineLink";
import Side from "../../../../components/Overlay/Side";
import ToggleLabels from "../../../../components/Overlay/ToggleLabels";

interface OverlayProps {
  panorama?: Panorama;
}

const Overlay = ({ panorama }: OverlayProps) => {
  return (
    <OverlayGrid
      columns="2fr 1fr"
      rows="10vw auto 10vw"
      areas={["side . link", "side . .", "side . audio"]}
    >
      <OverlayGrid.Area area="link">
        <TimeMachineLink />
        {panorama?.toggleLabels && <ToggleLabels />}
      </OverlayGrid.Area>

      <OverlayGrid.Area area="audio" className="flex items-end justify-end">
        <Player />
      </OverlayGrid.Area>

      <OverlayGrid.Area area="side" className="panorama period-title">
        {panorama && <Side resource={panorama} />}
      </OverlayGrid.Area>
    </OverlayGrid>
  );
};

export default Overlay;
