import create from "zustand";

interface ModelViewerState {
  animated_groups: AnimatedGroup[];
  setAnimatedGroups: (animated_groups: AnimatedGroup[]) => void;
  setAnimation: (group_name: string, value: boolean) => void;
}

const useModelStore = create<ModelViewerState>((set) => ({
  animated_groups: [],
  setAnimatedGroups: (animated_groups) =>
    set(() => {
      const animations = animated_groups.reduce(
        (obj, group) => ({ ...obj, [group.group_name]: false }),
        {},
      );
      return { animated_groups };
    }),
  setAnimation: (group_name, value) =>
    set((state) => ({
      animated_groups: state.animated_groups.map((group) => {
        if (group.group_name === group_name) {
          group.active = value;
        }
        return group;
      }),
    })),
}));

export default useModelStore;
