import { ReactComponent as BackInTimeIcon } from "../../icons/back-in-time.svg";

const TimeMachineLink = () => {
  const mapUrl = process.env.REACT_MAP_URL;
  return (
    <div className="flex justify-end p-8 md:p-4">
      <a
        href="/map/slug-napoli/"
        className="button_tm group pointer-events-auto flex h-[50px] flex-row items-center justify-end border-[1px] border-white bg-black/40 px-4 font-['Roboto'] text-lg font-semibold text-white hover:bg-white hover:text-black"
      >
        <BackInTimeIcon className="w-[33px] pr-2" />
        <span>TIME MACHINE</span>
      </a>
    </div>
  );
};

export default TimeMachineLink;
