import create from "zustand";

interface MapViewerState {
  map: CityMap;
  setMap: (map: CityMap) => void;
  loaded: string[];
  load: (slug: string) => void;
  selected_period: MapPeriod;
  setSelectedPeriod: (period: MapPeriod) => void;
  visible_layers: string[];
  toggleLayer: (layer_name: string, state?: boolean) => void;
}

const useStore = create<MapViewerState>((set, get) => ({
  map: undefined!,
  setMap: (map: CityMap) => {
    set(() => ({
      map,
    }));
    get().setSelectedPeriod(map.periods.find((period) => period.default)!);
  },
  loaded: [],
  load: (slug: string) => {
    if (!get().loaded.includes(slug)) {
      set((state) => ({ loaded: [...state.loaded, slug] }));
    }
  },
  selected_period: undefined!,
  setSelectedPeriod: (period: MapPeriod) => {
    set(() => ({ selected_period: period }));
    get().load(period.slug);
  },
  visible_layers: [],
  toggleLayer: (layer_name: string, state?: boolean) => {
    let visible_layers = get().visible_layers;
    if (state || !visible_layers.includes(layer_name)) {
      visible_layers = [...visible_layers, layer_name];
    } else {
      visible_layers = visible_layers.filter((l) => l !== layer_name);
    }
    set(() => ({ visible_layers }));
  },
}));

export default useStore;
