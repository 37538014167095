import { ReactComponent as Logo } from "../../icons/logo_main.svg";

interface TitleProps {
  strapline?: string;
  title: string;
}

const Title = ({ strapline, title }: TitleProps) => {
  return (
    <div className="mb-4 flex w-full flex-row items-start sm:pr-[70px]">
      <Logo className="logoMain w-[80px] min-w-[80px] pr-4 text-white xl:w-[4.5rem] md:w-[60px]" />
      <div className="flex h-full flex-col justify-center">
        {strapline && (
          <p className="font-[Roboto] text-lg font-[400] leading-6 text-white drop-shadow-md">
            {strapline}
          </p>
        )}
        <h2 className="font-['Rubik'] text-3xl font-[500] uppercase leading-8 text-period-color drop-shadow-md">
          {title}
        </h2>
      </div>
    </div>
  );
};

export default Title;
