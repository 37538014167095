import { useLoader } from "@react-three/fiber";
import { Suspense } from "react";
import { GLTFLoader } from "three-stdlib";

interface TerrainProps {
  src: string;
}

const Terrain = ({ src }: TerrainProps) => {
  const gltf = useLoader(GLTFLoader, src);
  return <primitive object={gltf.scene} />;
};

export default Terrain;
