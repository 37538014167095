import * as THREE from "three";
import { GLTF } from "three-stdlib";
import { useGLTF } from "@react-three/drei";
import { useEffect } from "react";
import Group from "./Group";

// Decided convention of internal structure of the models
type ModelStructure = GLTF & {
  nodes: {
    Scene: THREE.Group;
  };
};

interface BuildingProps {
  model: Model;
  onLoaded?: () => void;
}

const Model = ({ model, onLoaded = () => null }: BuildingProps) => {
  const {
    nodes: { Scene: rootScene },
  } = useGLTF(model.src) as ModelStructure;

  useEffect(() => {
    onLoaded();
  }, [rootScene, onLoaded]);

  return (
    <group dispose={null}>
      <group>
        {rootScene.children.map((child) => (
          <Group key={child.name} node={child} />
        ))}
      </group>
    </group>
  );
};

export default Model;
