import { useQuery } from "react-query";
import DLResource from "../utils/DLResource";

const usePanoramaQuery = (uuid: string) => {
  return useQuery<Panorama>("panorama", async () => {
    let panorama;
    if (uuid.startsWith("local-")) {
      panorama =  (await (
        await fetch(`/json/panorama/${uuid.slice(6)}.json`)
      ).json()) as Panorama;
    } else if(uuid.startsWith("slug-")){
      let slugMap = await (await fetch(`/json/slug-map.json`)
      ).json()
      uuid = slugMap[uuid.slice(5)]
      const gbltmResource = await DLResource.get(uuid);
      panorama = (await (
        await fetch(gbltmResource.url.toString())
      ).json()) as Panorama;
      
    }else {
      const gbltmResource = await DLResource.get(uuid);
      panorama = (await (
        await fetch(gbltmResource.url.toString())
      ).json()) as Panorama;
    }
    
    const panormaResource = await DLResource.get(panorama.src);
    switch (panormaResource.type) {
      case "FOTO360":
        panorama.src = panormaResource.buildURL("1/4096.jpg");
        break;
      case "GPX360":
        panorama.src = panormaResource.buildURL("1/4096.jpg");
        break;
    }
    return panorama;
  });
};

export default usePanoramaQuery;
