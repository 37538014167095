import { useQuery } from "react-query";
import DLResource from "../utils/DLResource";

const useMapQuery = (uuid: string) => {
  return useQuery("map", async () => {
    if (uuid.startsWith("local-")) {
      return (await (
        await fetch(`/json/map/${uuid.slice(6)}.json`)
      ).json()) as CityMap;
    }
    if(uuid.startsWith("slug-")){
      let slugMap = await (await fetch(`/json/slug-map.json`)
      ).json()
      if(slugMap[uuid.slice(5)]){
        uuid = slugMap[uuid.slice(5)]
      }
    }
    const dlResource = await DLResource.get(uuid);
    const map = (await (
      await fetch(dlResource.buildURL("data.json"))
    ).json()) as CityMap;
    map.terrain.src = dlResource.buildURL(map.terrain.src);
    for (let i in map.periods) {
      map.periods[i].src = dlResource.buildURL(map.periods[i].src);
    }
    return map;
  });
};

export default useMapQuery;
