import { useEffect, useState } from "react";
import useStore from "../../store";
import LayerSelect from "./LayerSelect";
import { ReactComponent as FilterIcon } from "../../../../icons/filter.svg";
import { ReactComponent as CloseIcon } from "../../../../icons/close.svg";

interface OptionProps {
  period: MapPeriod;
  selected: boolean;
  onSelected: (period: MapPeriod) => void;
}

const Option = ({
  period,
  onSelected = () => null,
  selected = false,
}: OptionProps) => {
  const [openLayerSelect, setOpenLayerSelect] = useState(false);
  const [periodHover, setPeriodHover] = useState(false);
  const handleSelected = () => onSelected(period);
  const load = useStore((state) => state.load);

  useEffect(() => setOpenLayerSelect(false), [selected]);

  return (
    <div className="period_selector flex-start pointer-events-auto relative mr-[45px] flex select-none items-end">
      <div
        className="layer_selector mr-[5px] flex h-[50px] w-[50px] cursor-pointer items-center justify-center"
        style={{
          backgroundColor: period.color,
          display: !selected ? "flex" : "none",
        }}
        onClick={() => setOpenLayerSelect(!openLayerSelect)}
      >
        {openLayerSelect ? (
          <CloseIcon className="w-6" />
        ) : (
          <FilterIcon className="w-6" />
        )}
      </div>
      <div className="flex flex-col justify-end">
        <div
          className={[
            !selected && openLayerSelect ? "" : "hidden",
            "w-full",
            "bg-white",
          ].join(" ")}
        >
          <LayerSelect
            layers={period.layers}
            onChange={() => load(period.slug)}
          />
        </div>
        <div
          style={{
            borderColor: period.color,
            color: selected
              ? "#ffffff"
              : periodHover
              ? "#000000"
              : period.color,
            textTransform: "capitalize",
            backgroundColor:
              selected || periodHover ? period.color : "#ffffff00",
          }}
          className="period_text flex h-[50px] cursor-pointer flex-row items-center justify-start border-[3px] px-4 font-['Rubik'] text-2xl font-[300]"
          onClick={handleSelected}
          onMouseLeave={() => setPeriodHover(false)}
          onMouseOver={() => setPeriodHover(true)}
        >
          {period.name}
        </div>
      </div>
    </div>
  );
};

export default Option;
