import useHotspotStore from "../../../../components/Hotspots/store";
import ActionList from "../../../../components/Overlay/ActionList";
import Title from "../../../../components/Overlay/Title";
import useStore from "../../store";

const Heading = () => {
  const selected_period = useStore((state) => state.selected_period);
  const list_action = useHotspotStore((state) => state.list_action);

  if (!selected_period) return null;

  return (
    <div
      className={`info ${
        list_action && "bg-black/70"
      } sx-col flex h-full w-1/3 min-w-[400px] max-w-[600px] flex-col items-start justify-start p-8 text-period-color`}
    >
      <Title strapline="Periodo" title={selected_period.name} />
      {list_action ? (
        <ActionList action={list_action} />
      ) : (
        selected_period.description && (
          <p className="mb-4 pr-20 font-[Roboto] text-xl font-[400] leading-6 text-white drop-shadow-md">
            {selected_period.description}
          </p>
        )
      )}
    </div>
  );
};

export default Heading;
