import OverlayGrid from "../../../../components/Overlay/OverlayGrid";
import Player from "../../../../components/Overlay/Player";
import ToggleLabels from "../../../../components/Overlay/ToggleLabels";
import useStore from "../../store";
import PeriodSelector from "../PeriodSelect";
import Heading from "./Heading";
import useHotspotStore from "../../../../components/Hotspots/store";

const Overlay = () => {
  const map = useStore((state) => state.map);

  const show_labels_distance = useHotspotStore(
    (state) => state.show_labels_distance,
  );
  return (
    <OverlayGrid
      columns="auto"
      rows="auto 10vh 15vh"
      areas={["title", "audio", "period"]}
    >
      <OverlayGrid.Area
        area="title / title / period / title"
        className="period-title"
      >
        <Heading />
      </OverlayGrid.Area>

      <OverlayGrid.Area
        area="period"
        className="main_period flex bg-gradient-to-t from-black"
      >
        <PeriodSelector />
      </OverlayGrid.Area>

      <OverlayGrid.Area area="audio" className="flex items-end justify-end">
        <Player />
        {map && map.toggleLabels && show_labels_distance && <ToggleLabels />}
      </OverlayGrid.Area>
    </OverlayGrid>
  );
};

export default Overlay;
