import { Environment, Stats } from "@react-three/drei";
import DevTools from "../../components/DevTools";
import CameraControls from "../../components/CameraControls";
import Map from "./components/MapModel";
import { Suspense } from "react";

interface SceneProps {
  map: CityMap;
}

const Scene = ({ map }: SceneProps) => {
  return (
    <>
      <CameraControls
        position={map.camera}
        min_distance={8.0}
        max_distance={64.5}
      />
      <Suspense fallback={null}>
        <Environment preset="city" />
      </Suspense>
      <Map />
    </>
  );
};

export default Scene;
