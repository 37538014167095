import { useState } from "react";
import { Link } from "react-router-dom";
import AudioPlayer from "../components/AudioPlayer";
import Modal from "../components/Modal";

const HomePage = () => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <div>
      <h1 className="text-3xl font-bold">Virtual Scabec</h1>
      <nav className="flex flex-col">
        <Link to="/map/slug-napoli">City Viewer</Link>
        <Link to="/map/local-napoli">Local City Viewer</Link>
        <h2 className="pt-4 text-xl font-bold">Model viewer</h2>
        <Link to="/model/slug-pio-monte">Pio Monte</Link>
        <Link to="/model/local-demo">Local Demo</Link>
        <h2 className="pt-4 text-xl font-bold">Panorama viewer</h2>
        <Link to="/panorama/slug-cappella">Panorama Viewer</Link>
        <Link to="/panorama/local-alpha">Local Panorama Viewer</Link>
      </nav>
      <AudioPlayer audio="sample_audio.mp4"></AudioPlayer>
      <button onClick={() => setOpenModal(true)}>Modal</button>
      {openModal && (
        <Modal title="Title" onClose={() => setOpenModal(false)}>
          Hello
        </Modal>
      )}
    </div>
  );
};

export default HomePage;
