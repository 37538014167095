import { Canvas } from "@react-three/fiber";
import { useEffect } from "react";
import Scene from "./Scene";
import { useParams } from "react-router-dom";
import Overlay from "./components/Overlay";
import useModelQuery from "../../hooks/useModelQuery";
import Modal from "../../components/Hotspots/Modal";
import LoadingOverlay from "../../components/Loading/LoadingOverlay";

const background_color = "#171319";

const ModelViewer = () => {
  const { uuid } = useParams();
  const { data: model } = useModelQuery(uuid!);

  useEffect(() => {
    if (model) {
      let r = document.querySelector(":root");
      if (r instanceof HTMLElement) {
        r.style.setProperty("--period-color", model.title_color);
      }
    }
  }, [model]);

  return (
    <div className="gradientCanvas h-screen w-screen">
      {model && (
        <>
          <Canvas>
            <Scene model={model} />
          </Canvas>
          <Overlay model={model} />
        </>
      )}
      <LoadingOverlay />
      <Modal />
    </div>
  );
};

export default ModelViewer;
