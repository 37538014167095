import { Suspense, useEffect, useState } from "react";
import Loading from "../../../../components/Loading";
import useStore from "../../store";
import Period from "./Period";
import Terrain from "./Terrain";

const Map = () => {
  const map_data = useStore((state) => state.map);
  const selected_period = useStore((state) => state.selected_period);
  const loaded = useStore((state) => state.loaded);
  const [loaded_periods, setLoadedPeriods] = useState<MapPeriod[]>([]);

  useEffect(() => {
    setLoadedPeriods([
      ...map_data.periods.filter((period) => loaded.includes(period.slug)),
    ]);
  }, [loaded]);

  if (!map_data) return null;

  return (
    <>
      <Suspense fallback={<Loading />}>
        <Terrain src={map_data.terrain.src} />
      </Suspense>
      {loaded_periods.map((period) => (
        <Suspense key={`period-${period.slug}`} fallback={<Loading />}>
          <Period show={selected_period.slug === period.slug} period={period} />
        </Suspense>
      ))}
    </>
  );
};

export default Map;
