import useStore from "../../../store";
import Option from "./Option";

interface LayerSelectProps {
  layers: PeriodLayer[];
  onChange?: () => void;
}

const LayerSelect = ({ layers, onChange = () => null }: LayerSelectProps) => {
  const toggleLayer = useStore((state) => state.toggleLayer);
  const handleChange = (layer: PeriodLayer, value: boolean) => {
    toggleLayer(layer.slug, value);
    onChange();
  };
  return (
    <>
      {layers.map((layer) => (
        <Option
          key={layer.slug}
          layer={layer}
          onChange={(value) => handleChange(layer, value)}
        />
      ))}
    </>
  );
};

export default LayerSelect;
