import { useEffect, useState } from "react";
import * as THREE from "three";
import useStore from "../../store";
import Mesh from "./Mesh";

type GroupElementProps = JSX.IntrinsicElements["group"];

interface GLTFModelGroupProps extends GroupElementProps {
  node: THREE.Group | THREE.Object3D;
  opacity?: number;
  groupPath?: string;
  show: boolean;
}

const Group = ({
  node: { children, type: _, ...node },
  opacity = 1,
  groupPath = "",
  show = true,
  ...props
}: GLTFModelGroupProps) => {
  const visible_layers = useStore((state) => state.visible_layers);
  const buildPath = (part: string) => `${groupPath}.${part}`;
  const [show_child, setShowChild] = useState(show);

  useEffect(() => {
    if (show || visible_layers.includes(node.name)) {
      return setShowChild(true);
    }
    setShowChild(false);
  }, [show, visible_layers, node.name]);

  return (
    <group {...node} {...props}>
      {children.map((child) => {
        const childPath = buildPath(child.name);
        if (child.type === "Mesh") {
          return (
            <Mesh
              key={childPath}
              opacity={opacity}
              {...child}
              show={show_child}
            />
          );
        }
        return (
          <Group
            key={childPath}
            opacity={opacity}
            node={child}
            groupPath={childPath}
            show={show_child}
          />
        );
      })}
    </group>
  );
};

export default Group;
