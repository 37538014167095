import { useEffect } from "react";
import { Canvas } from "@react-three/fiber";
import { useParams } from "react-router-dom";
import Scene from "./Scene";
import useMapQuery from "../../hooks/useMapQuery";
import useStore from "./store";
import Overlay from "./components/Overlay";
import Modal from "../../components/Hotspots/Modal";
import LoadingOverlay from "../../components/Loading/LoadingOverlay";

const background_color = "#171319";

const MapViewer = () => {
  const { uuid } = useParams();
  const { data: map } = useMapQuery(uuid!);
  const setMap = useStore((state) => state.setMap);

  useEffect(() => {
    if (map) {
      setMap(map);
    }
  }, [map, setMap]);

  return (
    <div className="h-screen w-screen">
      {map && (
        <>
          <Canvas onCreated={({ gl }) => gl.setClearColor(background_color)}>
            <Scene map={map} />
          </Canvas>
          <Overlay />
        </>
      )}
      <LoadingOverlay />
      <Modal />
    </div>
  );
};

export default MapViewer;
